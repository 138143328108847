<template>
  <div class="news-quote">
    <div class="news-quote__name list">
      <p v-if="card.author.photo === null" class="news-quote__photo" :style="`background: url('/assets/icons/profile_photo.svg') center / contain`"></p>
      <p v-else class="news-quote__photo" :style="`background: url('${this.$store.getters.getUrlApi + card.author.photo}') center / contain`"></p>
      <div>
        <p class="news-quote__name name_user">{{ `${card.author.firstName} ${card.author.lastName}` }}</p>
        <p class="news-quote__data">{{ dateArr(card.date) }}</p>
      </div>
    </div>
    <blockquote class="news-quote__block">
      <div>
        <div class="news-comment__text ckEditor-html" v-html="fullText ? card.text : sliceText(card.text)">
<!--        <span><router-link tag="span" :to="{ path: `comment/${card.id}` }"> Перейти к комментарию </router-link> </span>-->
        <span @click="seeFullText">{{textMore}}</span>
        </div>
      </div>
    </blockquote>
  </div>

</template>

<script>
export default {
  name: 'CommentNewBill',
  props: {
    card: {
      type: Object
    }
  },
  data () {
    return {
      textMore: 'Посмотреть весь текст',
      fullText: false
    }
  },
  methods: {
    seeFullText () {
      this.fullText = !this.fullText
      this.fullText === true ? this.textMore = 'Скрыть' : this.textMore = 'Посмотреть весь текст'
    },
    dateArr (date) {
      return (new Date(date).toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' }).slice(0, -3))
    },
    sliceText (text) {
      if (text.length > 170) {
        var str = text.slice(0, 170)
        var a = str.split(' ')
        a.splice(a.length - 1, 1)
        return a.join(' ') + ' ... '
      } else return text
    }
  }
}
</script>

<style scoped lang="scss">
.ckEditor-html {
  ::v-deep {
    * {
      margin: revert;
      padding: revert;
      list-style-type: revert;
      font-size: revert;
      font-weight: revert;
    }
  }
}
.news-quote {
  padding: 1.25rem 1.875rem;
  width: 100%;
  min-height: 11.5rem;
  box-shadow: 0rem .94rem 1.56rem rgba(189, 205, 221, 0.35);

  border: .06rem solid #EBF3FF;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-bottom: 1.87rem;

  &__photo {
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
  }

  &__name{
    display: flex;
    font-weight: 600;
    font-size: 0.88rem;
    line-height: 1.38rem;
    color: #1F3245;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0.25rem 0 0.25rem 1.25rem;
      .name_user{
        margin-bottom: 0.625rem;
      }
    }
  }

  &__data {
    font-size: 0.88rem;
    font-weight: normal;
    line-height: 1.38rem;
    color: rgba(23, 23, 23, 0.8);
  }

  &__block {
    position: relative;
    padding: 0.75rem 3.63rem .75rem 2.88rem;;

    background: #F1F4FA;
    border-radius: .5rem;

    ::v-deep .ckEditor-html *{
      font-style: italic;
      font-size: .875rem;
      font-weight: 400;
      line-height: 1.5rem;

      span{
          color: #246CB7;
      }
    }
  }
  &__block:before,
  &__block:after {
    position: absolute;
    color: #BFE2FF;
    font-size: 100px;
    font-family: Times, sans-serif;
    line-height: initial;
  }
  &__block:before {
    content: '”';
    color: #003E78;
    font-size: 2.5rem;
    left: 1rem;
    top: 0.75rem;
  }
  &__block:after {
    content: '”';
    color: #003E78;
    font-size: 2.5rem;
    right: 1rem;
    bottom: -0.7rem;
  }

  .list {
    margin-bottom: 0.75rem;
  }
}
</style>
