<template>
  <div>
    <!--    <bread-crumb :navigationArray="navigationLink"/>-->
    <router-link class="come-back" to="/public-examination-bills">
      <div></div>
      Вернуться в раздел “Законопроекты”
    </router-link>
    <div class="white-container">
      <p class="white-container__date">{{dateArr(date)}}</p>
      <h1>{{title}}</h1>
      <p class="ckEditor-html" v-html="billText"></p>
      <h2>Подробный текст законопроекта</h2>
      <documents-info v-for="item in document" :key="item.id + 'doc'" :content="{...item,url: `${$store.getters.getUrlApi}${item.url}`}" class="container__document"/>
    </div>
    <div class="white-container comment-form">
      <comment-form :source="'bill-draft'" class="main-activity comment-form">
<!--        <h2>Форма для отправки комментария к законопроекту</h2>-->
      </comment-form>
    </div>
    <div v-if="comments.length!==0" class="white-container comment-form">
      <div class="main-activity comment-form">
        <h2>Комментарии к посту</h2>
        <comment-new-bill v-for="item of comments" :card="item" :key="item.id"/>
      </div>
    </div>
    </div>
</template>
<script>
// import BreadCrumb from '@/components/BreadCrumb'
import DocumentsInfo from '@/components/DocumentsInfo'
import CommentForm from '@/components/activitiesSurveys/CommentForm'
import CommentNewBill from '@/components/activitiesSurveys/CommentNewBill'

export default {
  name: 'AdoptedBill',
  components: {
    // 'bread-crumb': BreadCrumb,
    'documents-info': DocumentsInfo,
    CommentForm,
    CommentNewBill

  },
  data () {
    return {
      isActiveTab: 0,
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'О палате',
          path: '/'
        },
        {
          title: 'Аппарат'
        }
      ],
      comments: [],
      date: '08.01.2020',
      title: '',
      document: [
        {
          createDate: '',
          name: '',
          url: '/',
          size: 235,
          type: 'PDF'
        }
      ],
      billText: ''
    }
  },
  created () {
    this.$store.dispatch('setCurrentBillDraft', this.$route.params.id)
  },
  computed: {
    getDetailBillDraft () {
      return this.$store.getters.getDetailBillDraft
    }
  },
  watch: {
    getDetailBillDraft () {
      this.setBillDraft()
    }
  },
  methods: {
    dateArr (date) {
      return (new Date(date).toLocaleString('ru', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      }).slice(0, -3))
    },
    // Обновляем данные
    setBillDraft () {
      const curBillDraft = this.$store.getters.getDetailBillDraft
      this.date = curBillDraft.date
      this.billText = curBillDraft.text
      this.document = curBillDraft.fullDocument
      this.title = curBillDraft.name
      document.title = curBillDraft.name
      // Добавляем комментарии
      // this.comments = [...this.currentTopic.comments]
      this.comments.length = 0
      curBillDraft.comments
        .filter(comment => {
          return comment.status === 'Опубликован'
        })
        .forEach(item => {
          this.comments.push({
            id: item.id,
            text: item.text,
            date: item.created,
            author: {
              photo: item.author.photo,
              firstName: item.author.firstName,
              lastName: item.author.lastName
            }
          })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.ckEditor-html {
  ::v-deep {
    * {
      margin: revert;
      padding: revert;
      list-style-type: revert;
      font-size: revert;
      font-weight: revert;
    }
  }
}
  .white-container {
    width: 61.44rem;
    margin: 0 5rem;
    padding: 2.5rem 2.94rem;

    &__date {
      font-size: .875rem;
      line-height: 1.37rem;
      color: #5E686C;
      opacity: 0.8;
      margin-bottom: 1.19rem;
    }

    &.comment-form {
      margin-top: 3.125rem;
    }

    p {
      margin-bottom: 2.5rem;
      margin-top: .75rem;
      line-height: 157%;
      font-size: .875rem;
      color: rgba(23, 23, 23, 0.8);
    }
  }

  h1 {
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 1.375rem;
    color: #1F3245;
  }

  .container__document {
    margin-top: 2rem;
  }
  .comment-form {
    margin-top: 3.125rem;
  }
  /*стрелка назад*/

  .come-back {
    /*display: block;*/
    width: 84.37rem;
    margin: .875rem auto;
    font-size: .75rem;
    line-height: 1.38rem;
    display: flex;
    justify-content: flex-start;
    color: #246CB7;
    cursor: pointer;
  }

  .come-back div {
    position: relative;
    top: .625rem;
    width: .75rem;
    height: .0925rem;
    background-color: #337AB7;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
    /*left:22em;*/
    display: block;
    border-radius: .820075px;
    margin-right: .4375rem;
  }

  .come-back div::after {
    content: '';
    position: absolute;
    width: .0825rem;
    height: .3125rem;
    top: -.23rem;
    left: .11rem;
    background-color: #337AB7;
    transform: rotate(-135deg);
    border-radius: .820075px;
  }

  .come-back div::before {
    content: '';
    position: absolute;
    width: .0825rem;
    height: .3125rem;
    /*top: 11px;*/
    left: .11rem;
    background-color: #337AB7;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
    transform: rotate(135deg);
    border-radius: .820075px;
  }

  @media screen and (max-width: 768px) {
    .white-container {
      width: auto;
      margin: 0 .75rem;
      padding: 1.86rem 1.25rem;
    }
    .come-back {
      width: 90%;
    }

  }

  @media screen and (max-width: 420px) {
    .white-container {
      width: 100%;
      margin: 0;
      padding: 1.88rem 1rem;
    }
  }
</style>
