<template>
  <section>
    <h2 v-show="!isToken" style="margin-bottom: 0;">
      <slot><router-link to="/registration" class="register-link">{{$store.getters.GET_LANG ? 'Зарегистрируйтесь, чтобы оставить комментарий' : 'Register to leave a comment'}}</router-link>
      </slot>
    </h2>
    <h2 v-show="isToken">{{$store.getters.GET_LANG ? 'Форма для отправки комментария' : 'Form for sending a comment'}}</h2>
    <form v-if="isToken" @submit.prevent="SubmitForm"
          id="app"
          method="post"
          ref="createComment"
    >
      <!--      <div class="comment-form-block">-->
      <!--        <div>-->
      <!--          <label for="name">ФИО*</label>-->
      <!--          <input-->
      <!--            class="input blue"-->
      <!--            id="name"-->
      <!--            v-model="name"-->
      <!--            type="text"-->
      <!--            name="name"-->
      <!--          >-->
      <!--        </div>-->
      <!--        <div>-->
      <!--          <label for="email">E-mail*</label>-->
      <!--          <input-->
      <!--            class="input blue"-->
      <!--            id="email"-->
      <!--            v-model="email"-->
      <!--            type="text"-->
      <!--            name="email"-->
      <!--          >-->
      <!--        </div>-->
      <!--      </div>-->
      <div>
        <label for="comment">{{$store.getters.GET_LANG ? 'Комментарий' : 'Comment'}}</label>
        <input
          class="input blue"
          id="comment"
          v-model="comment"
          type="text"
          name="comment"
          placeholder="Ваш комментарий"
        >
      </div>
      <!--      <div>-->
      <!--        <input-->
      <!--          class="custom-checkbox"-->
      <!--          id="checkbox"-->
      <!--          type="checkbox"-->
      <!--        >-->
      <!--        <label for="checkbox">Согласие обработки персональных данных “в соответствии с требованиями Федерального закона-->
      <!--          от 27.07.2006 года № 152-ФЗ «О персональных данных»</label>-->

      <!--      </div>-->
      <div>
        <input
          type="submit"
          :value="$store.getters.GET_LANG ? 'Отправить' : 'Submit'"
        >
      </div>
    </form>
  </section>

</template>

<script>
export default {
  name: 'CommentForm',
  props: {
    source: {
      type: String,
      default: 'post'
    }
  },
  data () {
    return {
      titleText: 'Форма для отправки комментария',
      isToken: localStorage.getItem('token') !== null ? 'true' : false,
      fullName: '',
      idPosts: Number(this.$route.params.id),
      comment: '',
      name: '',
      email: ''
    }
  },
  mounted () {
    // if (this.isToken) {
    //   return this.$store.dispatch('getPersonalData')
    // }
  },
  computed: {
    getUserMe () {
      return this.$store.getters.user
    },
    getPersonalData () {
      return this.$store.getters.getPersonalData
    }
  },
  watch: {
    getUserMe () {
      this.$store.getters.user !== null ? this.isToken = 'true' : this.isToken = false
    },
    getPersonalData () {
      this.setFullName()
    }
  },
  methods: {
    setFullName () {
      const presData = this.$store.getters.getPersonalData
      this.fullName = `${presData.firstName} ${presData.lastName} ${presData.patronymic || ''}`
    },
    async SubmitForm () {
      if (this.fullName.length === 0) {
        return this.setFullName()
      }
      const formData = new FormData(this.$refs.createComment)
      formData.append('text', this.comment)
      formData.append('title', this.fullName)
      const that = this
      await fetch(`${this.$store.getters.getUrlApi}api/user/${this.source}/${this.idPosts}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: formData
      })
        .then(
          function (response) {
            if (response.status !== 200) {
              that.$noty.error('Ошибка. Попробуйте отправить позже')
              console.log('Status Code: ' + response.status)
              if (response.status === 403) {
                that.$store.dispatch('logOut')
                that.isToken = false
                that.$noty.error('Ошибка. Войдите в личный кабинет и отправьте сообщение еще раз.')
              }
              return
            }
            if (that.source === 'post') {
              return that.$store.dispatch('apiDetailMemberPost', {
                id_member: that.$route.query.memberId,
                id_post: Number(that.$route.params.id)
              })
            }
            if (that.source === 'bill-draft') {
              that.$store.dispatch('setCurrentBillDraft', that.$route.params.id)
            }
            if (that.source === 'topic') {
              that.$store.dispatch('getCurrentTopic', that.$route.params.id)
            }
            that.$noty.success('Сообщение отправлено и будет опубликовано после модерации')
          }
        )
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
          that.$noty.error('Ошибка. Попробуйте отправить позже')
        })
    }

  }
}
</script>

<style scoped lang="scss">
  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5;
    color: #1F3245;
    margin-bottom: 2rem;
  }

  .comment-form-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & div {
      width: 26.5rem;
    }
  }

  .register-link {
    font-size: 1.125rem;
    font-weight: 600;
    color: #003E78;
    mix-blend-mode: normal;
    cursor: pointer;
  }

  .custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  ::v-deep label {
    text-align: left;
    font-size: .875rem;
    margin-bottom: .75rem;
    line-height: 1.57;
    color: #1F3245;
  }

  .custom-checkbox + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    font-size: 0.75rem;
    line-height: 1.33;
    width: 88%;
  }

  .custom-checkbox + label::before {
    content: '';
    display: inline-block;
    width: 1.125rem;
    height: 1.125rem;
    flex-shrink: 0;
    flex-grow: 0;
    border: 0;
    border-radius: .1875rem;
    margin-right: .6875rem;
    background-repeat: no-repeat;
    /*background-position: center center;*/
    background-size: 50% 50%;
    background-color: #DDEDFE;
    margin-top: -1rem;
  }

  /* стили при наведении курсора на checkbox */
  .custom-checkbox:not(:disabled):not(:checked) + label:hover::before {
    border-color: #b3d7ff;
  }

  /* стили для активного состояния чекбокса (при нажатии на него) */
  .custom-checkbox:not(:disabled):active + label::before {

  }

  /* стили для чекбокса, находящегося в состоянии checked */
  .custom-checkbox:checked + label::before {
    background-repeat: no-repeat;
    background-position: 58% center;
    background-color: #246CB7;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 5.5L5 9L12.5 1.5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }

  /* стили для чекбокса, находящегося в состоянии disabled */
  .custom-checkbox:disabled + label::before {
    background-color: #DDEDFE;
  }

  input {
    margin: 0;
    margin-bottom: 1.375rem;
  }

  input[type=submit] {
    margin-top: 1rem;
    width: 10rem;
    height: 2.1875rem;
    background: #246CB7;
    border-radius: .1875rem;
    color: #fff;
    font-size: .875rem;
    font-weight: 600;
    line-height: 1.43;
    cursor: pointer;

    &:hover {
      background: #246cb780;
    }
  }

  @media screen and (max-width: 768px) {
    .comment-form-block {
      div {
        width: 48%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .comment-form-block {
      div {
        width: 100%;
      }
    }
    .custom-checkbox + label {
      width: 100%;
    }
  }

</style>
